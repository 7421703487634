

















































































































































@import '~@/assets/styles/components/modal-cadastro';
.modal-lista-operacao-financeira .v-data-table__wrapper table {
  min-width: 600px;
}
